import React, { FC } from 'react';
import Contributors from '../Contributors/Contributors';
import ContributorType from 'models/Contributors/ContributorType';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  classes?: string;
  contributors?: ContributorType[];
  datePublished?: string;
}

const StoryCredits: FC<Props> = (props) => {
  const data = {
    contributors: props.contributors || [],
    hasContributors:
      props.contributors &&
      props.contributors.length > 0 &&
      props.contributors[0].profile,
    get datePublished() {
      return (
        props.datePublished &&
        dayjs.tz(props.datePublished, 'America/Chicago').format('MMMM DD, YYYY')
      );
    }
  };

  return (
    <>
      <div className={props.classes}>
        {data.hasContributors && (
          <p className="type-snug-up type-snug-down">
            by <Contributors authors={data.contributors} />
          </p>
        )}
        <p className="type-strong type-snug-up type-snug-down type-mini">
          {data.datePublished}
        </p>
      </div>
    </>
  );
};

export default StoryCredits;
