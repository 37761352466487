'use client';
import React, { FC } from 'react';
import { Body } from '@apmg/amat';
import { parseEmbeddedAssets } from 'utils';
import { DefaultCollection } from 'models/Collection/CollectionType';
import sty from './styles.module.css';
import PostArticle from 'components/Post/PostArticle';
import PostArea from 'components/Post/PostArea';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import PostCredits from 'components/Post/PostCredits';
import PostFeatureImage from 'components/Post/PostFeatureImage';
import StandardHero from 'components/StandardHero';

interface Props {
  collection: DefaultCollection;
}

const CollectionHero: FC<Props> = ({ collection }) => {
  const video = collection?.primaryVisuals?.video;
  const hasLeadImage = collection.primaryVisuals.lead;
  const hasBody = collection?.body;
  const embeds = collection?.embeddedAssets
    ? parseEmbeddedAssets(collection.embeddedAssets)
    : {};

  return (
    <>
      {hasLeadImage && (
        <PostArticle style={`post ${sty.article}`}>
          <PostArea>
            <div className={sty.topContainer}>
              <div>
                {video ? (
                  <>
                    <VideoPlayer video={video} />
                    <PostCredits contributors={collection.contributors} />
                  </>
                ) : (
                  <PostFeatureImage
                    primaryVisuals={collection.primaryVisuals}
                    showCaption={false}
                  />
                )}
              </div>
              <div>
                <StandardHero
                  title={collection.title}
                  subText={hasBody ? '' : collection.descriptionText}
                  cmsId={collection.id}
                  primaryCollectionSlug={collection.canonicalSlug}
                />
              </div>
            </div>
          </PostArea>
        </PostArticle>
      )}
      {!hasLeadImage && (
        <>
          <StandardHero
            title={collection.title}
            subText={hasBody ? '' : collection.descriptionText}
            cmsId={collection.id}
            primaryCollectionSlug={collection.canonicalSlug}
          />
        </>
      )}
      {collection.body && (
        <div className="userContent content_body type-article">
          <Body
            nodeData={JSON.parse(collection.body)}
            embedded={embeds}
            overrides={{}}
          />
        </div>
      )}
    </>
  );
};

export default CollectionHero;
