import React, { FC } from 'react';
import classNames from 'classnames';

interface Props {
  nodeData: {
    attrs: {
      url: string;
      short_caption: string;
      long_caption: string;
      credit_url: string;
      credit_name: string;
    };
  };
  embedded: {
    oembeds: OembedsType[];
  };
}

interface OembedsType {
  html: string;
  url: string;
  provider_name: string;
}

const ApmVideoOverride: FC<Props> = (props) => {
  const { short_caption, long_caption, credit_url, credit_name } =
    props.nodeData.attrs;

  function findEmbedded() {
    return props.embedded.oembeds.find((embed) => {
      return embed.url === props.nodeData.attrs.url;
    });
  }

  const embed = findEmbedded();
  if (!embed) {
    console.error(`No embed found for ${props.nodeData.attrs.url}`);
    return null;
  }
  const cname = embed?.provider_name.toLowerCase().replace(/\s/g, '');
  const classes = classNames({
    'apm-video': true,
    [cname]: cname
  });

  return (
    <figure
      className="figure"
      data-node-type="apm-video"
      data-url={props.nodeData.attrs.url}
    >
      <div
        className={classes}
        title={short_caption}
        dangerouslySetInnerHTML={{ __html: embed?.html }}
      />
      <figcaption className="figure_caption">
        <div className="figure_text">{long_caption}</div>
        <span className="figure_credit">
          {credit_url ? (
            <a href={credit_url}>{credit_name}</a>
          ) : (
            <div>{credit_name}</div>
          )}
        </span>
      </figcaption>
    </figure>
  );
};

export default ApmVideoOverride;
