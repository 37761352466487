import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/thecurrent/app/(routes)/[...slug]/CollectionHero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/thecurrent/app/(routes)/[...slug]/PageTemplate.tsx");
;
import(/* webpackMode: "eager" */ "/opt/thecurrent/app/(routes)/[...slug]/styles.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/thecurrent/components/Advertisement/AdvertisementBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/thecurrent/components/Teasers/Teasers.tsx");
;
import(/* webpackMode: "eager" */ "/opt/thecurrent/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/thecurrent/styles/modules/Pagination.module.css");
