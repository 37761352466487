import React, { FC } from 'react';

interface Props {
  style?: string;
  children?: React.ReactNode;
}

const PostArticle: FC<Props> = ({ children, style }) => {
  return (
    <>
      <article className={`${style} mt-1`}>{children}</article>
    </>
  );
};

export default PostArticle;
