import React, { FC } from 'react';
import ContributorType from 'models/Contributors/ContributorType';
import LinkNoPrefetch from 'components/LinkNoPrefetch/LinkNoPrefetch';

interface Props {
  items: ContributorType[];
}

const ContributorsLink: FC<Props> = (props) => {
  const { items } = props;

  return (
    <span>
      {items.map((item, i) => {
        if (!item.profile) {
          return null;
        }
        if (i === items.length - 1) {
          return (
            <LinkNoPrefetch
              key={i}
              href={`/profile/${item.profile.canonicalSlug}`}
              className="link link-plain"
            >
              {item.profile.title}
            </LinkNoPrefetch>
          );
        } else if (i === items.length - 2) {
          return (
            <span key={i}>
              <LinkNoPrefetch
                href={`/profile/${item.profile.canonicalSlug}`}
                className="link link-plain"
              >
                {item.profile.title}
              </LinkNoPrefetch>
              {''} and {''}
            </span>
          );
        }
        return (
          <span key={i}>
            <LinkNoPrefetch
              href={`/profile/${item.profile.canonicalSlug}`}
              className="link link-plain"
            >
              {item.profile.title}
            </LinkNoPrefetch>
            , {''}
          </span>
        );
      })}
    </span>
  );
};

export default ContributorsLink;
