import React, { FC } from 'react';
import useAds from 'hooks/useAds';
import styles from 'styles/modules/AdvertisementBanner.module.css';
import withAdSettings from './withAdSettings';

interface Props {
  id?: string;
  adUnitPath?: string;
  sizeMap?: Record<number, number[]>;
  sizes?: number[][];
  cmsId: string;
  primaryCollection: string;
  collections: string[];
  lazyload?: boolean;
}

const defaultAdUnitPath = '/22546217694/mpr.the_current';

const StoryAdInner: FC<Props> = ({
  id = 'story_ad',
  adUnitPath = defaultAdUnitPath,
  sizeMap = {
    0: [300, 250]
  },
  sizes = [[300, 250]],
  cmsId,
  primaryCollection,
  collections = [],
  lazyload = false
}) => {
  let adSize = sizes[0];
  if (process.browser) {
    const screenWidth = window.innerWidth;
    sizes.forEach((size: number[]) => {
      if (screenWidth >= size[0]) {
        adSize = size;
      }
    });
  }

  useAds({
    adUnitPath,
    id,
    sizes: adSize,
    cmsId,
    primaryCollection,
    collections,
    lazyload
  });

  return <div id={id} className={styles.advertisement}></div>;
};

interface BodyAdProps {
  nodeData: {
    id: string;
    adUnitPath: string;
    sizeMap: Record<number, number[]>;
    sizes: number[][];
    cmsId: string;
    primaryCollection: string;
    collections: string[];
    position: string;
  };
}
const BodyAdInner: FC<BodyAdProps> = (props) => {
  useAds({ ...props.nodeData });
  return <div id={props.nodeData.id}></div>;
};

export const StoryAd = withAdSettings<Props>(StoryAdInner);
export const BodyAd = withAdSettings<BodyAdProps>(BodyAdInner);
