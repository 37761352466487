import React, { FC, ReactElement } from 'react';

interface Props {
  inner: ReactElement | string;
  href: string;
  title: string;
}
const LinkOverride: FC<Props> = ({ inner, title, href }) => {
  const target = href.match(/yourclassical.org/) ? undefined : '_blank';
  const attrs = title ? { href, title, target } : { href, target };
  return <a {...attrs}>{inner}</a>;
};

export default LinkOverride;
