import React, { FC } from 'react';
import sty from './styles/StandardHero.module.css';
import PostTitle from 'components/Post/PostTitle';
import Image from 'next/image';

export interface StandardHeroProps {
  title: string;
  subText: string;
  underwriting?: string;
  cmsId?: string;
  primaryCollectionSlug?: string;
  imageurl?: string;
  imageWidth?: number;
  imageHeight?: number;
}

const StandardHero: FC<StandardHeroProps> = ({
  title,
  subText,
  cmsId,
  primaryCollectionSlug,
  imageurl,
  imageWidth,
  imageHeight
}) => {
  return (
    <div className={sty.standardHero}>
      <div className={sty.standardHeroContainer}>
        {imageurl && (
          <Image
            src={imageurl}
            alt={title}
            width={imageWidth}
            height={imageHeight}
          />
        )}
        <PostTitle
          title={title}
          useAutoTypeSnug={false}
          cmsId={cmsId}
          primaryCollectionSlug={primaryCollectionSlug}
        />
        <div
          className="type-hefty"
          dangerouslySetInnerHTML={{ __html: subText }}
        />
      </div>
    </div>
  );
};

export default StandardHero;
