import React, { FC } from 'react';
interface Props {
  style?: string;
  children?: React.ReactNode;
}

const PostArea: FC<Props> = ({ children, style }) => {
  return <div className={style}>{children}</div>;
};

export default PostArea;
