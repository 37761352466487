'use client';
import React, { FunctionComponent } from 'react';
import { Body } from '@apmg/amat';

import { parseEmbeddedAssets } from 'utils';
import { BodyAd } from 'components/Advertisement/StoryAd';
import PrimaryVisuals from 'models/PrimaryVisuals/PrimaryVisualType';
import ContributorType from 'models/Contributors/ContributorType';
import AudioType from 'models/Audio/AudioType';
import EmbeddedAssets from 'models/Content/EmbeddedAssetsType';
import PrimaryCollection from 'models/PrimaryCollection/PrimaryCollectionType';

import CollectionTypeWSlugOnly from 'models/Collection/CollectionTypeWSlugOnly';
import LinkOverride from 'components/Overrides/LinkOverride';
import PostCredits from 'components/Post/PostCredits';
import PostFeatureImage from 'components/Post/PostFeatureImage';
import BodyWrapper from 'components/Layout/BodyWrapper';
import PostArticle from 'components/Post/PostArticle';
import PostTitle from 'components/Post/PostTitle';
import PostArea from 'components/Post/PostArea';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import RelatedItem from 'models/RelatedItem/RelatedItemType';
import ApmVideoOverride from 'components/Overrides/ApmVideoOverride';
import { AdjacentEpisode } from 'models/Episode/EpisodeType';

interface Props {
  id: string;
  title: string;
  shortTitle?: string;
  subtitle?: string;
  publishDate?: string;
  descriptionText: string;
  body: string;
  primaryVisuals: PrimaryVisuals;
  embeddedAssets?: EmbeddedAssets;
  resourceType: string;
  canonicalSlug: string;
  contributors?: ContributorType[];
  audio?: AudioType[];
  errorCode?: number | null;
  primaryCollection: PrimaryCollection | null;
  collections: CollectionTypeWSlugOnly[];
  allowComments?: boolean;
  relatedItems?: RelatedItem[];
  previousEpisode?: AdjacentEpisode | null;
  nextEpisode?: AdjacentEpisode | null;
  addFavorite?: React.ReactNode;
}

const PagePage: FunctionComponent<Props> = (props) => {
  const nodeData = JSON.parse(props.body);
  const video = props?.primaryVisuals?.video;

  const embeds = props?.embeddedAssets
    ? parseEmbeddedAssets(props.embeddedAssets)
    : {};

  return (
    <BodyWrapper>
      <PostTitle title={props.title} topic={props.primaryCollection} />
      <PostArticle style="post">
        <PostArea>
          {video ? (
            <>
              <VideoPlayer video={video} />
              <PostCredits
                contributors={props.contributors}
                datePublished={
                  props.resourceType !== 'page' ? props.publishDate : undefined
                }
              />
            </>
          ) : (
            <>
              {props.primaryVisuals.lead && (
                <PostFeatureImage primaryVisuals={props.primaryVisuals} />
              )}
              <PostCredits
                contributors={props.contributors}
                datePublished={
                  props.resourceType !== 'page' ? props.publishDate : undefined
                }
              />
            </>
          )}
          <div className="userContentContainer">
            <Body
              nodeData={nodeData}
              embedded={embeds}
              overrides={{
                adinsert: BodyAd,
                link: LinkOverride,
                apm_video: ApmVideoOverride
              }}
            />
          </div>
        </PostArea>
      </PostArticle>
    </BodyWrapper>
  );
};

export default PagePage;
